.slick-slider {

  .slick-arrow {
    width: 48px !important;
    height: 48px !important;

    &::before {
      font-size: 48px !important;
    }

    &.slick-prev {
      left: 20px !important;
      z-index: 1;
    }

    &.slick-next {
      right: 20px !important;
      z-index: 1;
    }
  }

  .slick-dots {
    li {
      button {
        &:before {
          color: #F9F9F9;
        }
      }
    }
  }

  .slick-list {
    .slick-track {
      .slick-slide {
        padding: 0 20px;

        .img-responsive {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .slide-footer {
          .btn-view-more {
            font-size: .8em;
          }

          .slide-title {
            color: #9D1227;
          }

          .slide-subtitle {
            color: #9D1227;
          }
        }
      }
    }
  }
}
