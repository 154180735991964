@use 'sass:map';
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$navbar-padding-y: 0 !important;

@import "bootstrap/scss/bootstrap";

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }
    .border#{$infix}-end {
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }
    .border#{$infix}-start {
      border-left: $border-width solid $border-color !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-end-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-start-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .overflow#{$infix}-auto {
      overflow: auto !important;
    }
    .overflow#{$infix}-hidden {
      overflow: hidden !important;
    }
    .overflow#{$infix}-visible {
      overflow: visible !important;
    }
    .overflow#{$infix}-scroll {
      overflow: scroll !important;
    }

    .overflow#{$infix}-x-auto {
      overflow-x: auto !important;
    }
    .overflow#{$infix}-x-hidden {
      overflow-x: hidden !important;
    }
    .overflow#{$infix}-x-visible {
      overflow-x: visible !important;
    }
    .overflow#{$infix}-x-scroll {
      overflow-x: scroll !important;
    }

    .overflow#{$infix}-y-auto {
      overflow-y: auto !important;
    }
    .overflow#{$infix}-y-hidden {
      overflow-y: hidden !important;
    }
    .overflow#{$infix}-y-visible {
      overflow-y: visible !important;
    }
    .overflow#{$infix}-y-scroll {
      overflow-y: scroll !important;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .w#{$infix}-100-vw {
      width: 100vw !important;
    }
    .w#{$infix}-95-vw {
      width: 95vw !important;
    }
    .w#{$infix}-90-vw {
      width: 90vw !important;
    }
    .w#{$infix}-85-vw {
      width: 85vw !important;
    }
    .w#{$infix}-80-vw {
      width: 80vw !important;
    }
    .w#{$infix}-75-vw {
      width: 75vw !important;
    }
    .w#{$infix}-70-vw {
      width: 70vw !important;
    }
    .w#{$infix}-65-vw {
      width: 65vw !important;
    }
    .w#{$infix}-60-vw {
      width: 60vw !important;
    }
    .w#{$infix}-55-vw {
      width: 55vw !important;
    }
    .w#{$infix}-50-vw {
      width: 50vw !important;
    }
    .w#{$infix}-45-vw {
      width: 45vw !important;
    }
    .w#{$infix}-40-vw {
      width: 40vw !important;
    }
    .w#{$infix}-35-vw {
      width: 35vw !important;
    }
    .w#{$infix}-30-vw {
      width: 30vw !important;
    }
    .w#{$infix}-25-vw {
      width: 25vw !important;
    }
    .w#{$infix}-20-vw {
      width: 20vw !important;
    }
    .w#{$infix}-15-vw {
      width: 15vw !important;
    }
    .w#{$infix}-10-vw {
      width: 10vw !important;
    }
    .w#{$infix}-5-vw {
      width: 5vw !important;
    }



    .w#{$infix}-100 {
      width: 100% !important;
    }
    .w#{$infix}-95 {
      width: 95% !important;
    }
    .w#{$infix}-90 {
      width: 90% !important;
    }
    .w#{$infix}-85 {
      width: 85% !important;
    }
    .w#{$infix}-80 {
      width: 80% !important;
    }
    .w#{$infix}-75 {
      width: 75% !important;
    }
    .w#{$infix}-70 {
      width: 70% !important;
    }
    .w#{$infix}-65 {
      width: 65% !important;
    }
    .w#{$infix}-60 {
      width: 60% !important;
    }
    .w#{$infix}-55 {
      width: 55% !important;
    }
    .w#{$infix}-50 {
      width: 50% !important;
    }
    .w#{$infix}-45 {
      width: 45% !important;
    }
    .w#{$infix}-40 {
      width: 40% !important;
    }
    .w#{$infix}-35 {
      width: 35% !important;
    }
    .w#{$infix}-30 {
      width: 30% !important;
    }
    .w#{$infix}-25 {
      width: 25% !important;
    }
    .w#{$infix}-20 {
      width: 20% !important;
    }
    .w#{$infix}-15 {
      width: 15% !important;
    }
    .w#{$infix}-10 {
      width: 10% !important;
    }
    .w#{$infix}-5 {
      width: 5% !important;
    }
    .w#{$infix}-0 {
      width: 0% !important;
    }


    .w#{$infix}-auto {
      width: fit-content !important;
    }

    .h#{$infix}-100-vh {
      height: 100vh !important;
    }
    .h#{$infix}-95-vh {
      height: 95vh !important;
    }
    .h#{$infix}-90-vh {
      height: 90vh !important;
    }
    .h#{$infix}-85-vh {
      height: 85vh !important;
    }
    .h#{$infix}-80-vh {
      height: 80vh !important;
    }
    .h#{$infix}-75-vh {
      height: 75vh !important;
    }
    .h#{$infix}-70-vh {
      height: 70vh !important;
    }
    .h#{$infix}-65-vh {
      height: 65vh !important;
    }
    .h#{$infix}-60-vh {
      height: 60vh !important;
    }
    .h#{$infix}-55-vh {
      height: 55vh !important;
    }
    .h#{$infix}-50-vh {
      height: 50vh !important;
    }
    .h#{$infix}-45-vh {
      height: 45vh !important;
    }
    .h#{$infix}-40-vh {
      height: 40vh !important;
    }
    .h#{$infix}-35-vh {
      height: 35vh !important;
    }
    .h#{$infix}-30-vh {
      height: 30vh !important;
    }
    .h#{$infix}-25-vh {
      height: 25vh !important;
    }
    .h#{$infix}-20-vh {
      height: 20vh !important;
    }
    .h#{$infix}-15-vh {
      height: 15vh !important;
    }
    .h#{$infix}-10-vh {
      height: 10vh !important;
    }
    .h#{$infix}-5-vh {
      height: 5vh !important;
    }


    .h#{$infix}-100 {
      height: 100% !important;
    }
    .h#{$infix}-95 {
      height: 95% !important;
    }
    .h#{$infix}-90 {
      height: 90% !important;
    }
    .h#{$infix}-85 {
      height: 85% !important;
    }
    .h#{$infix}-80 {
      height: 80% !important;
    }
    .h#{$infix}-75 {
      height: 75% !important;
    }
    .h#{$infix}-70 {
      height: 70% !important;
    }
    .h#{$infix}-65 {
      height: 65% !important;
    }
    .h#{$infix}-60 {
      height: 60% !important;
    }
    .h#{$infix}-55 {
      height: 55% !important;
    }
    .h#{$infix}-50 {
      height: 50% !important;
    }
    .h#{$infix}-45 {
      height: 45% !important;
    }
    .h#{$infix}-40 {
      height: 40% !important;
    }
    .h#{$infix}-35 {
      height: 35% !important;
    }
    .h#{$infix}-30 {
      height: 30% !important;
    }
    .h#{$infix}-25 {
      height: 25% !important;
    }
    .h#{$infix}-20 {
      height: 20% !important;
    }
    .h#{$infix}-15 {
      height: 15% !important;
    }
    .h#{$infix}-10 {
      height: 10% !important;
    }
    .h#{$infix}-5 {
      height: 5% !important;
    }
    .h#{$infix}-0 {
      height: 0% !important;
    }
    .h#{$infix}-auto {
      height: fit-content !important;
    }
  }
}

.row.equal-cols {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row.equal-cols:before,
.row.equal-cols:after {
  display: block;
}

.row.equal-cols > [class*='col-'] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row.equal-cols > [class*='col-'] > * {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
