.event-info-container {
   //background: url('../../assets/images/portfolio_background_1.png'), var(--newgrad3, linear-gradient(90deg, #9D1227 0%, #47070F 80.76%));
   background: url('../../assets/images/portfolio_background_1.png'), var(--newgrad3, #200004);
   background-position: top center;
   background-size: 100%;
   background-repeat: no-repeat;
   color: white;
   padding: 50px 0 100px 0;

   @media screen and (max-width: 768px) {
      background-size: contain;
   }

   .container-title {
      .event-icon {
         width: 141px;
      }
   }

   .text-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 50px 0;

      .text-content {
         max-width: 700px;
         padding: 0 20px;

         p {
            font-size: .875em;
            line-height: 1.5;
            font-weight: 300;
            text-align: justify;
         }
      }
   }

   .event-controller {
      .btn-register {
         background: #FFF;
         border-radius: 0 !important;
         font-family: Beautique Display, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
         transition: all .3s ease-in-out;

         .text-gradient {
            background: var(--newgrad4, linear-gradient(345deg, #230005 0%, #5B0915 15.63%, #9D1227 31.77%, #E72D49 54.17%, #47070F 73.44%));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding: 5px 0;
         }

         &:hover {
            transform: scale(1.05);
         }
      }
   }
}
