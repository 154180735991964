:root {
  --animate-duration: 1000ms;
  --animate-delay: 0.9s;
}

a.link {
  position: relative;
  transition: color .3s ease-in-out;
  height: 100%;
  display: inline-flex;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 100%;
    height: .5px;
    background: linear-gradient(345deg, #230005 0%, #5B0915 15.63%, #9D1227 31.77%, #E72D49 54.17%, #47070F 73.44%);
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
  }

  &:hover {
    color: #F9F9F9;
  }

  &:hover::before {
    transform: scaleX(1);
  }
}

.scroll-top {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #F9F9F9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;

  i{
    color: #2E050A;
  }
}



