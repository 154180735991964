.banner-container {
  //background: url('../../assets/images/background_pattern.png'), var(--newgrad, linear-gradient(90deg, #9D1227 0%, #47070F 100%));
  background: url('../../assets/images/top_background_pattern_1.png'), url('../../assets/images/top_background_pattern_3.png'), url('../../assets/images/top_background_pattern_2.png'), var(--newgrad, #200004);
  background-position: top left, top center, top right;
  background-size: 25%, auto, 25%;
  background-repeat: no-repeat;
  color: white;

  .topBar {

    .topBar__logo {
      flex: 1;

      .top-row {
        height: 50%;
        border-bottom: 1px solid white;

        img.logo {
          width: 201px;
        }
      }
    }

    .topBar__icon {
      flex: 0;

      img.event-icon {
        width: 138px;
      }
    }

    .topBar__search {
      flex: 1;

      .top-row {
        height: 50%;
        border-bottom: 1px solid white;
      }
    }
  }

  .navbar {
    width: fit-content;
    //background: #5E000E;
    //box-shadow: 0 2px 10px 0 rgba(255, 22, 22, 0.31);
    background: rgba(0, 0, 0, 0.50);
    box-shadow: 0 2px 10px 0 rgba(255, 22, 22, 0.20);
    padding: 5px 20px;

    .navbar-nav {

      .nav-item {
        margin: 0 20px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .nav-link {
          color: #F9F9F9;
          text-shadow: 0 4px 4px #68000F;
          font-family: "Beautique Display", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        }
      }

      @media screen and (max-width: 768px) {
        .nav-item {
          margin: 0 5px;
        }
      }
    }
  }


  .video_area {
    width: 100%;
    height: auto;

    .embed-responsive {
      width: 100%;
      padding: 0;
      overflow: hidden;

      &::before {
        display: block;
        content: "";
      }

      iframe {
        transform: scale(2);
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    @media screen and (max-width: 768px) {
      .embed-responsive {
        height: calc(100vh * .45);
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .embed-responsive {
        height: calc(100vh * .7);
      }
    }

    @media screen and (min-width: 1200px) {
      .embed-responsive {
        height: calc(100vh * .7);
      }
    }

    @media screen and (min-width: 2000px) {
      .embed-responsive {
        height: calc(100vh * .7);
      }
    }
  }
}
