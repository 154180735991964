a {
  color: #8e8e8e;
  text-decoration: none;
  background-color: transparent;
}

.btn-goto-home {
  background: #d32f2f;
  padding: 10px 15px;
  border-radius: 10px;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.btn-goto-home span {
  text-decoration: underline;
}

#error {
  position: relative;
  height: 100vh
}

#error .error {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.error {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center
}

.error .error-content {
  position: relative;
  height: 240px
}

.error .error-content h1 {
  font-family: "Lucida Grande", Helvetica, Arial, sans-serif;;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px
}

.error .error-content h1 > span {
  text-shadow: -8px 0 0 #fff
}

.error .error-content h3 {
  font-family: "Lucida Grande", Helvetica, Arial, sans-serif;;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0;
  letter-spacing: 3px;
  padding-left: 6px
}

.error h2 {
  font-family: "Lucida Grande", Helvetica, Arial, sans-serif;;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 0;
  margin-bottom: 25px
}

@media only screen and (max-width: 767px) {
  .error .error-content {
    height: 200px
  }

  .error .error-content h1 {
    font-size: 200px
  }
}

@media only screen and (max-width: 480px) {
  .error .error-content {
    height: 162px
  }

  .error .error-content h1 {
    font-size: 162px;
    height: 150px;
    line-height: 162px
  }

  .error h2 {
    font-size: 16px
  }
}