$menu-item-number: 3;
$menu-segment-number: $menu-item-number * 2;
$menu-height: 64px;
$menu-width: 100vw;
$current-menu-item: 0;
$menu-bg-color: #ffffff;

$menu-item-width: 64px;
$menu-item-height: 64px;

$icon-width: 16px;
$icon-height: 16px;
$icon-font-size: 16px;
$icon-color: #666;
$icon-active-color: #FFFFFF;
$icon-active-font-size: 18px;

$menu-item-color: #666;
$menu-item-active-color: #720a0a;

$menu-bagde-color: #9D1227;


input[type=radio] {
  display: none;
}

div.nav {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: $menu-width;
  height: $menu-height;
  border-radius: 10px;

  .nav-bg {
    display: flex;
    flex-direction: row;
    //border-radius: 10px;
    overflow-x: clip;

    .nav-bg-left {
      content: ' ';
      display: flex;
      flex-direction: row;
      justify-content: end;
      width: ($menu-width / $menu-segment-number * ( $current-menu-item + 1));
      height: $menu-height;
      position: relative;
      left: 0;
      transition: width .3s ease-in-out;

      .bg {
        flex: 1;
        background: $menu-bg-color;
        //border-radius: 10px 0 0 10px;
      }


      .nav-bg-left-top {
        flex: 0 0 auto;
        width: $menu-item-width;
        height: $menu-item-height;

        svg {
          width: 100%;
          height: 100%;
          fill: $menu-bg-color;
        }
      }
    }

    .nav-bg-right {
      content: ' ';
      display: flex;
      flex-direction: row;
      justify-content: start;
      width: ($menu-width - ($menu-width / $menu-segment-number * ($current-menu-item + 1)));
      height: $menu-height;
      position: relative;
      left: 0;
      transition: width .3s ease-in-out;

      .bg {
        flex: 1;
        background: $menu-bg-color;
      }

      .nav-bg-right-top {
        flex: 0 0 auto;
        width: $menu-item-width;
        height: $menu-item-height;

        svg {
          width: 100%;
          height: 100%;
          fill: $menu-bg-color;
        }
      }
    }
  }

  ul {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    height: $menu-height;
    display: flex;
    flex-direction: row;
    justify-content: space-around;


    li {
      list-style: none;
      width: $menu-item-width;
      height: $menu-item-height;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;


      label {
        width: $menu-item-width;
        height: $menu-item-height;
        position: relative;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;


        div {
          width: 24px;
          height: 24px;
          background: $menu-bg-color;
          border-radius: 50%;
          position: absolute;
          top: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all .3s ease-in-out;

          i {
            font-size: $icon-font-size;
            color: $icon-color;
            transition: all .3s ease-in-out;
          }
        }

        span {
          font-size: .8em;
          color: $menu-item-color;
          margin-top: 20px;
        }
      }

      &:hover {
        label {
          div {

            i {
              transform: scale(1.2);
              //color: $icon-active-color;
            }
          }

          span {
            color: $menu-item-active-color;
          }
        }
      }
    }
  }
}

@for $i from 1 through $menu-item-number {
  $current-menu-item: $i * 2 - 2;
  #item-#{$i}:checked ~ .nav-bg {
    .nav-bg-left {
      width: ($menu-width / $menu-segment-number * ( $current-menu-item + 1));
    }

    .nav-bg-right {
      width: ($menu-width - ($menu-width / $menu-segment-number * ($current-menu-item + 1)));
    }
  }
  #item-#{$i}:checked ~ ul {
    li:nth-child(#{$i}) {
      label {
        div {
          width: 56px;
          height: 56px;
          background: $menu-bagde-color;
          border-radius: 50%;
          position: absolute;
          top: -28px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

          i {
            font-size: $icon-active-font-size;
            color: $icon-active-color;
          }
        }

        span {
          color: $menu-item-active-color;
        }
      }
    }
  }
}
