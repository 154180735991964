.condition-member-container {
  //background: url('../../assets/images/condition_member_background_1.png'), url('../../assets/images/condition_member_background_2.png'), radial-gradient(134.37% 173.11% at 50.00% -69.86%, #901023 31.47%, #4C010B 100%);
  background: url('../../assets/images/condition_member_background_1.png'), url('../../assets/images/condition_member_background_2.png'), #200004;
  background-position: bottom left, right top;
  background-size: 25%, 25%;
  background-repeat: no-repeat;
  color: white;
  padding: 50px 0 100px 0;

  @media screen and (max-width: 768px) {
    background-size: 40%, 40%;
  }

  .container-title {

  }

  .condition-container {
    width: 100%;
    margin: 80px auto 0 auto;

    .row {
      .card-column {
        .card {
          border-radius: 1.25rem;
          border: none;
          background: radial-gradient(134.37% 173.11% at 50.00% -69.86%, #901023 31.47%, #4C010B 100%);
          box-shadow: 0 11px 18px -4px rgba(0, 0, 0, 0.40), 0px 4px 4px 0px rgba(210, 4, 53, 0.25);
          padding: 10px;
          min-height: 20vh;

          .card-body {
            color: #F9F9F9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            h1, h2 {
              padding: 0;
              margin: 0;

              span {
                font-style: italic;
              }
            }

            span {
              font-size: .875em;
            }
          }
        }
      }
    }

    .condition-content {
      margin: 80px auto 0 auto;
      cursor: pointer;

      p {
        font-size: .875em;
      }

      * {
        transition: all .3s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
