.footer-container {
  //background: url('../../assets/images/footer_background.png'), linear-gradient(360deg, #A01429 0%, #2E050A 100%);
  background: url('../../assets/images/footer_background.png'), #200004;
  background-position: top center;
  background-size: auto;
  background-repeat: no-repeat;
  color: white;
    padding: 10px 0 100px 0;


  .topBar {

    .topBar__left {
      flex: 1;

      .top-row {
        height: 50%;
        border-bottom: 1px solid white;

        img.logo {
          width: 201px;
        }
      }
    }

    .topBar__icon {
      flex: 0;

      img.event-icon {
        width: 138px;
      }
    }

    .topBar__right {
      flex: 1;

      .top-row {
        height: 50%;
        border-bottom: 1px solid white;
      }
    }
  }

  .container {
    .row {
      .footer-column {
        .footer-title {
          height: 64px;

          .inter-title {
            font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
            font-size: 1.5rem;
          }

          svg {
            height: 2rem;
            margin-bottom: .5rem;
          }
        }

        .bankName {
          font-size: .875em;
          font-weight: 400;
          cursor: pointer;
          font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        }

        .social-links {
          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              padding: 5px;
              font-size: 1em;
              font-weight: 400;
              cursor: pointer;
              font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
            }
          }
        }

        .footer-link {
          margin: 0 0 30px 0;

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              padding: 0 0 5px 0;
              font-size: 1em;
              font-weight: 400;
              cursor: pointer;
              font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
              text-align: justify;

              &:not(:first-child) {
                padding: 5px 0;
              }

            }

          }
        }

        .row {
          .social-column {

            .footer-social-card {
              border-radius: 16px;
              padding: 5px;
              background: #FFFFFF;

              .card-body {
                color: #2E050A;

                .card-image {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  border: 1px solid #2E050A;
                }

                p {
                  font-size: .6em;
                  font-weight: 600;
                  margin: 0;
                  padding: 0;
                }

                span {
                  font-size: .4em;
                  font-weight: 600;
                  margin: 0;
                  padding: 0;
                }
              }
            }

            @media screen and (max-width: 768px) {
              .footer-social-card {
                border-radius: 16px;
                padding: 5px;
                background: #FFFFFF;

                .card-body {
                  color: #2E050A;

                  .card-image {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    border: 1px solid #2E050A;
                  }

                  p {
                    font-size: 1em;
                    font-weight: 600;
                    margin: 0;
                    padding: 0;
                  }

                  span {
                    font-size: .875em;
                    font-weight: 600;
                    margin: 0;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


}
