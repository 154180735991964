.slider-container {
  //background: url('../../assets/images/background_pattern_2.png'), var(--newgrad2, radial-gradient(82.89% 82.89% at 50.00% -53.49%, #9D1227 0%, #47070F 100%));
  background: url('../../assets/images/background_pattern_2.png'), var(--newgrad2, #FFFCF5);
  background-position: top left;
  background-size: 10%;
  background-repeat: no-repeat;
  color: #727272;
  padding: 50px 0 100px 0;

  @media screen and (max-width: 768px){
    background-size: 25%;
  }

  .container-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    h2 {
      width: fit-content;
      background: var(--newgrad-07, linear-gradient(326deg, #230005 0%, #5B0915 27.21%, #951326 50.43%, #BB283C 70.80%, #47070F 100%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .slick-slider {


    .slick-dots {
      li {
        button {
          &:before {
            color: #9D1227;
          }
        }
      }
    }
  }
}
