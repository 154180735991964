.contact-container {
  //background: url('../../assets/images/contact_background_1.png'), radial-gradient(100.00% 100.00% at 50.00% 0.00%, #9D1227 0%, #47070F 100%);
  background: url('../../assets/images/contact_background_1.png'), url('../../assets/images/condition_member_background_2.png'), #FFFCF5;
  background-position: bottom left, top right;
  background-size: 20%, 20%;
  background-repeat: no-repeat, no-repeat;
  color: white;
  padding: 50px 0 100px 0;

  @media screen and (max-width: 768px) {
    background-size: 40%, 40%;
  }

  .container-title {

  }

  .row {
    .contact-column {

      .contact-title {
        h2 {
          background: var(--newgrad-07, linear-gradient(326deg, #230005 0%, #5B0915 27.21%, #951326 50.43%, #BB283C 70.80%, #47070F 100%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      img {
        position: relative;
        z-index: 1;
      }


      .contact-form {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        width: 110%;
        z-index: 0;

        .input-group {
          margin-bottom: 10px;

          input {
            padding: 10px 50px 10px 10px;
            border: 1px solid #9D1227;
            border-radius: 2px;
            background: rgba(255, 255, 255, 0);
            color: #9D1227;
            box-shadow: none;
            outline: none;

            &:focus {
              outline: 1px solid #9D1227;
            }

            &::placeholder {
              color: rgba(157, 18, 39, .5);
            }

          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-background-clip: text;
            -webkit-text-fill-color: #ffffff;
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px #23232329;
          }

          small {
            font-size: .6em;
            color: #ff2e2e;
          }


        }

        @media screen and (max-width: 768px) {
          padding: 10px;
        }


        .btn-contact {
          //background: #FFF;
          background: var(--grad-01, linear-gradient(345deg, #230005 0%, #5B0915 15.63%, #951326 31.77%, #BB283C 54.17%, #47070F 73.44%));
          border-radius: 0 !important;
          font-family: Beautique Display, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
          text-transform: uppercase;
          width: 50%;
          font-weight: bold;
          padding: 10px 20px;
          position: relative;
          transition: all .3s ease-in-out;

          @media screen and (max-width: 768px) {
            width: 70%;
          }

          span {
            padding: 0;
            margin: 0;

            .text-gradient {
              //background: var(--newgrad4, linear-gradient(345deg, #230005 0%, #5B0915 15.63%, #9D1227 31.77%, #E72D49 54.17%, #47070F 73.44%));
              //-webkit-background-clip: text;
              //-webkit-text-fill-color: transparent;
              color: #FFFFFF;
              padding: 10px 0;
              font-style: normal;
              font-weight: 500;
              display: flex;
              flex-direction: column;

              span {
                white-space: nowrap;
                text-wrap: none;
              }
            }
          }

          .spin-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            transition: all .5s ease-in-out;
            opacity: 0;

            i {
              font-size: 40px;
              color: #9D1227;
            }
          }

          &.loading {
            .spin-container {
              opacity: 1;
            }
          }

          &:hover {
            transform: scale(1.05);
          }
        }


        .error-container {
          text-align: center;
          color: #ff2e2e;

          .error-feedback {
            font-size: .8em;
          }
        }

        .notify-container {
          text-align: center;
          color: #22ffa8;
          margin-top: 10px;

          .notify-feedback {
            font-size: .8em;
          }
        }


      }

      @media screen and (max-width: 992px) {
        .contact-form {
          width: 100%;

          input {
            padding: 10px;
          }
        }
      }

    }
  }
}
